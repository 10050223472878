<template>
  <!-- <div class="container"> -->

  <div class="container text-center" :style="{
    backgroundImage: `url(${this.bgurl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100vw',
    marginTop: '-10px'
  }">
    <!-- Konten lainnya di sini -->
    <br />
    <br />
    <div class="text-white">
      <p style="font-size: 30px">Baiturrahman Mobile</p>
      <p>Aplikasi untuk Orangtua Santri</p>
    </div>
    <br />
    <br />
    <br />

    <b-card class="col-md-4 px-0 mt-4 mb-4 text-center shadow" style="opacity: 1" header="Login Page">
      <b-form>
        <b-input-group size="md">
          <b-form-input v-model="$globalData.UserId" placeholder="NIS santri" />
          <b-input-group-append is-text>
            <b-icon-person-check />
          </b-input-group-append>
        </b-input-group>
        <!-- {{ bgurl }} -->
        <!-- <b-input-group size="md" class="mt-2">
          <b-form-input
            v-model="tgllahir"
            :type="type"
            title="tgl lahir (mm/dd/yyyy)"
            placeholder="tgl lahir (mm/dd/yyyy)"
          />
          <b-input-group-append is-text>
            <b-icon-key title="tgl lahir (mm/dd/yyyy)" />
          </b-input-group-append>
        </b-input-group> -->
        <!-- <b-input-group size="md" class="mt-2" v-show="type == 'date'"> -->
        <b-input-group size="md" class="mt-2">
          <b-form-input v-model="password" :type="passwordtype ? 'password' : 'text'" placeholder="password" />
          <b-input-group-append is-text>
            <b-icon-key @click="passwordtype = !passwordtype" />
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <p style="font-size: 10px" class="text-bottom">
        password pertama = tgllahir (yyyy-mm-dd)
      </p>
      <b-button class="float-right mt-1 col-6" block @click="gologin"><b-icon-arrow-right />Login</b-button>

    </b-card>
    <!-- {{ $globalData.LoginOK ? "Login Sukses ... " : "Login Gagal.." }} -->
    <br />
    <br />
    <br />
    <br />
    <p style="font-size: 12px" class="text-bottom">
      Baiturrahman Copyright 2024
    </p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      tgllahir: "",
      ketlogin: "",
      bgurl: this.$appUrl + "bg.jpg",
      passwordtype: true,
    };
  },
  computed: {
    type() {
      return this.$func.checkData(this.$globalData.UserId);
    },
  },

  mounted() {
    this.$globalData.UserId = localStorage.getItem("userid") || "";
    this.$globalData.LoginOK = "no";
    this.tgllahir = "2007-05-03";
  },
  methods: {
    gettahun() {
      axios.get(this.$appUrl + "gettahun.php").then((res) => {
        this.$globalData.CurrentYear = res.data;
      });
    },
    gologin_() {
      this.$globalData.islogin = false;
      this.$router.push({ path: "/jurnal" });
    },
    gologin() {
      this.gettahun();
      axios
        .post(this.$appUrl + "login.php?jenis=" + this.type, {
          username: this.$globalData.UserId,
          password: this.password
        })
        .then((res) => {
          console.log("reslogin", res.data);

          if (res.data) {
            localStorage.setItem("userid", this.$globalData.UserId);
            this.$globalData.pasfoto = res.data.user[0].pasfoto;
            this.$globalData.Token = res.data.token;
            this.$globalData.datauser = res.data.user[0];
            localStorage.setItem("datauser", JSON.stringify(this.$globalData.datauser));
            this.$globalData.LoginOK = "yes";
            let kode = Date.now() + Math.floor(Math.random() * 1000);
            localStorage.setItem('authToken', kode);
            localStorage.setItem('token', res.data.token)
            this.$router.push({ path: "/produksi/" + kode });

            this.ketlogin = "Login Sukses ......";
            if (this.type == "date") {
              // this.$router.push({ path: "/menu" });
              this.$router.push({ path: "/menu/" + kode });
            } else {
              this.$router.push({ path: "/adminizin" });
            }
          } else {
            this.$globalData.LoginOK = "no";
            this.$globalData.TokenOK = 0;
            this.ketlogin = "Login gagal";
            this.$globalData.islogin = true;

            this.$globalData.Token = "";
            this.$bvModal.msgBoxOk("User & Password tidak sesuai ...", {
              centered: true,
            });
          }
        });
    },
  },
};
</script>

<style>
#app {
  margin-top: 0cm !important;
  /* margin-bottom: 2cm; */
}
</style>
