import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/main.css";
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

import "./assets/css/bootstrap-icons-1.11.3/font/bootstrap-icons.min.css";
import { func } from "@/assets/js";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;
Vue.prototype.$func = func;

if (process.env.NODE_ENV === "development") {
  // Vue.prototype.$appUrl = "http://localhost:8800/sisbeer.mobile/";
  // Vue.prototype.$appUrl = "http://192.168.1.195:8800/sisbeer.mobile/";
  Vue.prototype.$appUrl = "http://192.168.100.29:8800/sisbeer.mobile/";
} else {
  Vue.prototype.$appUrl = "php.script/";
}

Vue.prototype.$globalData = Vue.observable({
  UserId: "",
  LoginOK: "no",
  Token: "",
  CurrentYear: "24",
  // Token: localStorage.getItem("mytokenBonding"),
  LevelLogin: "",
  ShowChangePassword: false,
  islogin: true,
  pasfoto: "",
  hakuser: [],
  datauser: {},
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
