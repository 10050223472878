import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import Menu from "../views/menu.vue";
import Adminizin from "../views/adminizin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/menu/:id?",
    name: "menu",
    component: Menu,
  },
  {
    path: "/adminizin",
    name: "adminizin",
    component: Adminizin,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
