<template>
    <div class="container text-center px-1">
        <!-- <div class="container text-center px-1" style="margin-top: 1.5cm !important"> -->
        <b-card class="col-md-4 px-0  mb-4 text-center shadow" no-body header="Aktivitas Murojaah Santri">
            <b-icon-arrow-repeat @click="refresh" class="position-absolute" title="Refresh" size="sm"
                style="top: 21px; right: 20px; z-index: 100; cursor: pointer" />
            <b-table :items="info" :fieldsx="['tanggal', 'aktivitas', 'hadir', 'namawali']" hover responsive
                :busy="isBusy" style="font-size: 13px" class="text-left" @row-clicked="dataselected">
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle" size="sm"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>
        </b-card>
        <b-modal v-model="modal" :title="pmb.aktivitas" ok-only scrollable>
            <div style="font-size: 12px;">Rating 1:Kurang Lancar, 2:Lancar, 3:Sangat Lancar</div>
            <div class="row">
                <b-table :items="detail" :fieldsx="['tanggal', 'aktivitas', 'hadir', 'namawali']" hover responsive small
                    :busy="isBusy" style="font-size: 12px" class="text-left" />
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "AktivitasSantrimrj",
    props: ["data"],
    components: {
        //  Navbar,
    },
    data() {
        return {
            password: "",
            tgllahir: "",
            ketlogin: "",
            urladdress: "https://apps.baiturrahman.com/php.script/",
            info: [],
            detail: [],
            isBusy: false,
            modal: false,
            pmb: {},
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.isBusy = true;
            axios
                .get(
                    this.$appUrl +
                    "getkegiatanmrj.php?token=" +
                    this.$globalData.Token +
                    "&nis=" +
                    this.data.nis +
                    "&jenis=resume"
                )
                .then((res) => {
                    this.isBusy = false;

                    this.info = res.data;
                });
        },
        dataselected(item) {
            console.log("item", item)
            axios
                .get(
                    this.$appUrl +
                    "getkegiatanmrj.php?token=" +
                    this.$globalData.Token +
                    "&nis=" +
                    this.data.nis +
                    "&jenis=detail&nopmb=" +
                    item.nopmb
                )
                .then((res) => {
                    this.isBusy = false;
                    this.pmb = item;
                    this.modal = true;
                    this.detail = res.data;
                });
        },
    },
};
</script>

<style></style>
